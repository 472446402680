import { WhatsappIcon } from '~/components/atoms/Icons/WhatsappIcon'

export const buttonParamsByType = {
  whatsapp: {
    hrefURL: 'https://api.whatsapp.com/send?phone=5511957712414&text=(ISA) Oi, Principia! Pode me ajudar?',
    label: (
      <>
        <WhatsappIcon className="mr-2" /> Falar com atendimento
      </>
    ),
  },
}
