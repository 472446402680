import { useContext, useMemo } from 'react'
import { ButtonV3 as Button } from '@provi/provi-components'
import { IDataToShow, IReadIntroGuarantorResponse } from 'types'
import Tip from '~/components/molecules/Tip'
import * as S from './style'
import { addMask } from '~/utils/addMask'
import { CheckoutContext } from '~/contexts/CheckoutContext'

interface IIntroGuarantorContent {
  data: IDataToShow
  backendData: IReadIntroGuarantorResponse | null
  handleClick: (selectNewPaymentMethod: boolean) => void
}

const IntroGuarantorContent = ({ data, handleClick, backendData }: IIntroGuarantorContent) => {
  const { isBanned, primaryButtonShouldShowIcon, secondaryButtonText, primaryButtonText, text, title, tip } = data
  const { CPF, fullName } = backendData?.lastGuarantorAttemptInfo || {}
  const { isSendingData } = useContext(CheckoutContext)

  const isButtonForNewGuarantor = !primaryButtonText?.toLowerCase().includes('indicar')

  const renderPersonalDataContainer = useMemo(() => {
    return (
      <S.PersonalDataContainer>
        <S.IntroGuarantorContentText isVariant={true}>
          Não conseguimos aprovar a pessoa que você indicou:
        </S.IntroGuarantorContentText>

        <S.IntroGuarantorContentPersonalInformation>
          {fullName && <S.IntroGuarantorContentText isVariant={true}>{fullName}</S.IntroGuarantorContentText>}
          {CPF && (
            <>
              <S.IntroGuarantorContentText isBold={true} isVariant={true} as="span">
                CPF:{' '}
              </S.IntroGuarantorContentText>
              <S.IntroGuarantorContentText isVariant={true} as="span" data-recording-sensitive>
                {addMask(CPF || '', '###.###.###-##')}
              </S.IntroGuarantorContentText>
            </>
          )}
        </S.IntroGuarantorContentPersonalInformation>
      </S.PersonalDataContainer>
    )
  }, [CPF, fullName])

  const renderTip = useMemo(() => {
    if (tip) {
      return <Tip text={tip} />
    }

    if (isBanned) {
      return <Tip text={`Você só poderá começar um novo financiamento daqui ${backendData?.restrictionDays || 40} dias.`} />
    }

    return (
      <Tip
        text={`Você já fez ${backendData?.numberOfAttempts || 1} tentativa de indicação de avalista e ainda pode tentar mais ${
          backendData?.remainingAttempts || 1
        } vez.`}
      />
    )
  }, [tip, isBanned, backendData?.numberOfAttempts, backendData?.remainingAttempts, backendData?.restrictionDays])

  return (
    <S.IntroGuarantorContentWrapper>
      <S.IntroGuarantorContentTitle>{title}</S.IntroGuarantorContentTitle>
      {CPF && fullName && renderPersonalDataContainer}
      <S.IntroGuarantorContentText as="div">{text}</S.IntroGuarantorContentText>
      {renderTip}
      <S.IntroGuarantorContentButtons isBig={(secondaryButtonText && secondaryButtonText?.length >= 20) || false}>
        {secondaryButtonText && (
          <Button
            text={secondaryButtonText}
            color="white"
            onClick={() => handleClick(true)}
            width="fit-content"
            mobileWidth="100%"
          />
        )}

        <Button
          text={primaryButtonText}
          icon={primaryButtonShouldShowIcon ? true : false}
          width={`${primaryButtonText.length * 10}px`}
          mobileWidth="100%"
          onClick={() => handleClick(isButtonForNewGuarantor)}
          isLoading={isSendingData}
        />
      </S.IntroGuarantorContentButtons>
    </S.IntroGuarantorContentWrapper>
  )
}

export default IntroGuarantorContent
