import styled, { css } from 'styled-components'
import { theme } from '@provi/provi-components'
import { Body2 } from '~/styles/global'

export const FinancingSuccessDataTextBox = styled.div``

export const FinancingSuccessDataText = styled(Body2)<{ isTitle?: boolean }>`
  ${({ isTitle }) =>
    isTitle &&
    css`
      font-weight: 600;
      margin-bottom: 24px;

      ${theme.breakpoints.down('md')} {
        margin-bottom: 8px;
      }
    `}
`
