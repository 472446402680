import { useMemo } from 'react'
import FeedbackHeaderCheckout from '~/components/molecules/FeedbackHeaderCheckout'
import { addMask } from '~/utils/addMask'
import { useGuarantorDenied } from './hooks'
import * as S from './style'

const GuarantorDenied = () => {
  const { guarantorData } = useGuarantorDenied()

  const renderGuarantorDeniedData = useMemo(() => {
    if (!guarantorData && process.env.NODE_ENV !== 'test') {
      return (
        <S.GuarantorDeniedDataContainer>
          {Array.from({ length: 5 }).map((_, index) => (
            <S.GuarantorDeniedLoading key={index} />
          ))}
        </S.GuarantorDeniedDataContainer>
      )
    }

    return (
      <S.GuarantorDeniedDataContainer>
        <S.GuarantorDeniedDataTextBox>
          <S.GuarantorDeniedDataText>Não conseguimos aprovar a pessoa que você indicou.</S.GuarantorDeniedDataText>
        </S.GuarantorDeniedDataTextBox>

        <S.GuarantorDeniedDataTextBox>
          <S.GuarantorDeniedDataText data-recording-sensitive>{guarantorData?.guarantor.fullName}</S.GuarantorDeniedDataText>
          <S.GuarantorDeniedDataText data-recording-sensitive>
            <strong>CPF: </strong>
            {guarantorData?.guarantor.CPF && addMask(guarantorData.guarantor.CPF, '###.###.###-##')}
          </S.GuarantorDeniedDataText>
        </S.GuarantorDeniedDataTextBox>

        <S.GuarantorDeniedDataTextBox>
          <S.GuarantorDeniedDataText>
            Você já indicou {guarantorData?.numberOfAttempts} pessoas mas, neste momento, não conseguimos aprovar nenhuma delas
            como avalista.
          </S.GuarantorDeniedDataText>
        </S.GuarantorDeniedDataTextBox>

        <S.GuarantorDeniedDataTextBox>
          <S.GuarantorDeniedDataText>Sentimos muito não poder te ajudar dessa vez.</S.GuarantorDeniedDataText>
        </S.GuarantorDeniedDataTextBox>

        <S.GuarantorDeniedDataTextBox>
          <S.GuarantorDeniedDataText>
            Daqui {guarantorData?.restrictionDays} dias você poderá fazer um novo pedido de financiamento.
          </S.GuarantorDeniedDataText>
        </S.GuarantorDeniedDataTextBox>
      </S.GuarantorDeniedDataContainer>
    )
  }, [guarantorData])

  return (
    <S.GuarantorDeniedWrapper>
      <FeedbackHeaderCheckout isSuccess={false} title={'Seu pedido de financiamento foi recusado'} />
      {renderGuarantorDeniedData}
    </S.GuarantorDeniedWrapper>
  )
}

export default GuarantorDenied
