import { InputFieldV3 as Input } from '@provi/provi-components'
import cep from 'cep-promise'
import { FormikProps } from 'formik'
import { ChangeEvent, KeyboardEvent, useEffect, useMemo, useRef, useState } from 'react'
import { brazilianStates } from '~/components/templates/Checkout/AddressScreen/states'
import { ISinglePageCheckout } from '~/components/templates/Checkout/SinglePageCheckout/hooks'
import { masks } from '~/enums/masks'
import { Body2 } from '~/styles/global'
import { IBrazilianState, ICepPromise, IOptionalFieldsToDisplay } from '~/types/index'
import { removeMask } from '~/utils/removeMask'
import * as S from './styles'

interface IAddressSection {
  formik: Pick<FormikProps<any>, 'setFieldValue' | 'setFieldTouched' | 'validateForm' | 'errors' | 'touched' | 'values'>
  handleEnterKey: (event: KeyboardEvent<HTMLFormElement>) => void
  optionalFieldsToDisplay: IOptionalFieldsToDisplay
  handleOnBlurSubmission: (field: keyof ISinglePageCheckout) => void
  shouldShowText: boolean
}

export const AddressSection = ({
  formik,
  handleEnterKey,
  optionalFieldsToDisplay,
  handleOnBlurSubmission,
  shouldShowText,
}: IAddressSection) => {
  const [isInputLoading, setIsInputLoading] = useState(false)

  const nbhInputRef = useRef<HTMLInputElement>(null)
  const streetNumberInputRef = useRef<HTMLInputElement>(null)

  const { setFieldValue, setFieldTouched, validateForm, errors, touched, values } = formik

  useEffect(() => {
    if (values.zipcode && removeMask(values.zipcode).length === 8) {
      setIsInputLoading(true)

      cep(values.zipcode)
        .then((data: ICepPromise) => {
          !values.state && data?.state && setFieldValue('state', data?.state || '')
          !values.neighborhood && data?.neighborhood && setFieldValue('neighborhood', data?.neighborhood || '')
          !values.district && data?.neighborhood && setFieldValue('district', data?.neighborhood || '')
          !values.city && data?.city && setFieldValue('city', data?.city || '')
          !values.street && data?.street && setFieldValue('street', data?.street || '')

          !values.state && data?.state && setFieldTouched('state')
          !values.neighborhood && data?.neighborhood && setFieldTouched('neighborhood')
          !values.district && data?.neighborhood && setFieldTouched('district')
          !values.city && data?.city && setFieldTouched('city')
          !values.street && data?.street && setFieldTouched('street')

          setIsInputLoading(false)

          if (!!data?.state && !!data?.city && !data?.street) {
            nbhInputRef.current?.focus()
            return
          }

          if (data?.street) {
            streetNumberInputRef.current?.focus()
            return
          }
        })
        .catch(() => {
          document.getElementById('react-select-2-input')?.focus()
        })
        .finally(() => setIsInputLoading(false))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.zipcode, setIsInputLoading])

  const renderAddressSection = useMemo(() => {
    return (
      <div className="space-y-4">
        {shouldShowText && (
          <Body2 className="max-w-[464px]">
            Para esse método de pagamento, precisamos de algumas informações adicionais. Preencha os campos abaixo para
            continuar.
          </Body2>
        )}
        {!optionalFieldsToDisplay.phone && (
          <Input
            inputMode="numeric"
            pattern="[0-9]*"
            type="text"
            label="Celular"
            placeholder="(11) 95771-2412"
            width="184px"
            name="phone"
            value={values.phone}
            mask={masks.phone}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue('phone', e.target.value)}
            onBlur={() => {
              setFieldTouched('phone')
              validateForm()
              handleOnBlurSubmission('phone')
            }}
            isValid={!errors.phone && touched.phone}
            hasError={errors.phone && touched.phone}
            errorMessage={errors.phone}
            onKeyDown={handleEnterKey}
          />
        )}

        {optionalFieldsToDisplay.birthDate && (
          <Input
            inputMode="numeric"
            pattern="[0-9]*"
            type="text"
            label="Data de nascimento"
            placeholder="12/12/2000"
            width="368px"
            name="birthDate"
            mask={masks.birthDate}
            value={values.birthDate}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue('birthDate', e.target.value)}
            onBlur={() => {
              setFieldTouched('birthDate')
              validateForm()
              handleOnBlurSubmission('birthDate')
            }}
            isValid={!errors.birthDate && touched.birthDate}
            hasError={errors.birthDate && touched.birthDate}
            errorMessage={errors.birthDate}
            onKeyDown={handleEnterKey}
            required
          />
        )}
        {optionalFieldsToDisplay.RG && (
          <Input
            label="RG"
            mask={masks.rg}
            placeholder="12345678-9"
            name="RG"
            width="364px"
            value={values.RG}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue('RG', e.target.value)}
            onBlur={() => {
              setFieldTouched('RG')
              validateForm()
              handleOnBlurSubmission('RG')
            }}
            isValid={!errors.RG && touched.RG}
            hasError={errors.RG && touched.RG}
            errorMessage="Informe um RG válido"
            onKeyDown={handleEnterKey}
          />
        )}

        <Input
          inputMode="numeric"
          pattern="[0-9]*"
          type="text"
          label="CEP"
          placeholder="23530-024"
          width="184px"
          name="zipcode"
          mask={masks.cep}
          value={values.zipcode}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue('zipcode', e.target.value)}
          onBlur={() => {
            setFieldTouched('zipcode')
            validateForm()
          }}
          isValid={!errors.zipcode && touched.zipcode}
          hasError={errors.zipcode && touched.zipcode}
          errorMessage={errors.zipcode}
          onKeyDown={handleEnterKey}
        />
        <div>
          <S.LabelSelector>Estado</S.LabelSelector>
          <S.SelectContent
            name="state"
            id="input-state"
            placeholder="Selecionar estado"
            value={values.state}
            onChange={(event: ChangeEvent<HTMLSelectElement>) => {
              setFieldValue('state', event.target.value)
            }}
            onBlur={() => {
              setFieldTouched('state')
              validateForm()
            }}
          >
            <option value="" hidden>
              Selecionar estado
            </option>
            {brazilianStates.map((state: IBrazilianState) => (
              <option key={state.acronym} value={state.acronym}>
                {state.label}
              </option>
            ))}
          </S.SelectContent>
        </div>
        <Input
          label="Cidade"
          placeholder="Rio de Janeiro"
          width="368px"
          name="city"
          value={values.city}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue('city', e.target.value)}
          onBlur={() => {
            setFieldTouched('city')
            validateForm()
          }}
          isValid={!errors.city && touched.city}
          hasError={errors.city && touched.city}
          errorMessage={errors.city}
          onKeyDown={handleEnterKey}
          isLoading={isInputLoading}
        />
        <Input
          label="Bairro"
          placeholder="Rio Comprido"
          width="368px"
          value={values.district}
          name="district"
          onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue('district', e.target.value)}
          onBlur={() => {
            setFieldTouched('district')
            validateForm()
          }}
          isValid={!errors.district && touched.district}
          hasError={errors.district && touched.district}
          errorMessage={errors.district}
          onKeyDown={handleEnterKey}
          inputRef={nbhInputRef}
          isLoading={isInputLoading}
        />
        <Input
          label="Endereço"
          placeholder="Rua Lourival Lópes"
          width="368px"
          value={values.street}
          name="street"
          onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue('street', e.target.value)}
          onBlur={() => {
            setFieldTouched('street')
            validateForm()
          }}
          isValid={!errors.street && touched.street}
          hasError={errors.street && touched.street}
          errorMessage={errors.street}
          onKeyDown={handleEnterKey}
          isLoading={isInputLoading}
        />
        <S.InputContent>
          <Input
            label="Número"
            placeholder="119"
            width="160px"
            value={values.streetNumber}
            name="streetNumber"
            onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue('streetNumber', e.target.value)}
            onBlur={() => {
              setFieldTouched('streetNumber')
              validateForm()
            }}
            isValid={!errors.streetNumber && touched.streetNumber}
            hasError={errors.streetNumber && touched.streetNumber}
            errorMessage={errors.streetNumber}
            onKeyDown={handleEnterKey}
            inputRef={streetNumberInputRef}
            isLoading={isInputLoading}
          />
          <Input
            label="Complemento"
            placeholder="Apto 13"
            width="184px"
            value={values.complement || ''}
            name="complement"
            onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue('complement', e.target.value)}
            onBlur={() => {
              setFieldTouched('complement')
              validateForm()
            }}
            isValid={!errors.complement && touched.complement && values.complement}
            hasError={errors.complement && touched.complement}
            errorMessage={errors.complement}
            onKeyDown={handleEnterKey}
            isLoading={isInputLoading}
          />
        </S.InputContent>
      </div>
    )
  }, [
    errors,
    handleEnterKey,
    handleOnBlurSubmission,
    isInputLoading,
    nbhInputRef,
    optionalFieldsToDisplay,
    streetNumberInputRef,
    touched,
    validateForm,
    values,
    setFieldTouched,
    setFieldValue,
    shouldShowText,
  ])

  return renderAddressSection
}
