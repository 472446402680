import { WhatsappIcon } from '~/components/atoms/Icons/WhatsappIcon'
import CheckoutSuccess from '../../CheckoutSuccess'
import { useInvalidCheckout } from './hooks'

const InvalidCheckout = () => {
  const { invalidCheckoutText } = useInvalidCheckout()

  return (
    <>
      <CheckoutSuccess
        isSuccess={false}
        text={invalidCheckoutText.text}
        title={invalidCheckoutText.title}
        shouldTalkToUs={true}
        isWithButton={true}
        hrefURL="https://api.whatsapp.com/send?phone=5511957712414&text=Oi, Principia! Pode me ajudar?"
        buttonText={
          <>
            <WhatsappIcon className="mr-2" /> Falar com atendimento
          </>
        }
      />
    </>
  )
}

export default InvalidCheckout
