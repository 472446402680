import { Disclosure } from '@headlessui/react'
import { ButtonV3 as Button, InputFieldV3 as Input } from '@provi/provi-components'
import { ChangeEvent, useMemo } from 'react'
import { ChevronRightIcon } from '~/components/atoms/ChevronRightIcon'
import { InputFieldEmail } from '~/components/atoms/InputFieldEmail'
import { InformationTextWithTooltip, LoadingInput } from '~/components/molecules'
import { BoletoForm, CourseFinancingForm, CreditCardForm, PaymentMethodCards } from '~/components/organisms'
import PixForm from '~/components/organisms/PixForm'
import { useSinglePageCheckout } from '~/components/templates/Checkout/SinglePageCheckout/hooks'
import { AddressSection } from '~/components/templates/Checkout/SinglePageCheckout/Sections/AddressSection'
import { masks } from '~/enums/masks'
import { Body1, Body2 } from '~/styles/global'

export const SinglePageCheckout = () => {
  const {
    values,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    handleEnterKey,
    validateForm,
    setFieldTouched,
    isLoadingData,
    isSendingData,
    chosenPaymentMethod,
    temporaryCreditCardData,
    setTemporaryCreditCardData,
    submitButtonText,
    setIsCreditCardValid,
    isButtonDisabled,
    handleOnBlurSubmission,
    optionalFieldsToDisplay,
    shouldShowCourseFinancingForm,
    firstName,
    shouldShowReason,
    reason,
    imTheCardHolder,
    setImTheCardHolder,
    fullNameRef,
    disclosureButtonRef,
  } = useSinglePageCheckout()

  const renderChosenPaymentMethodForm = useMemo(() => {
    switch (chosenPaymentMethod) {
      case 'CreditCard':
        return (
          <CreditCardForm
            temporaryCreditCardData={temporaryCreditCardData}
            setTemporaryCreditCardData={setTemporaryCreditCardData}
            imTheCardHolder={imTheCardHolder}
            setImTheCardHolder={setImTheCardHolder}
            setIsValid={setIsCreditCardValid}
            hideSubmit
          />
        )
      case 'Boleto':
        return <BoletoForm hideSubmit />
      case 'Pix':
        return <PixForm hideSubmit />
      case 'CourseFinancing':
        return shouldShowCourseFinancingForm ? (
          <CourseFinancingForm firstName={firstName} hideSubmit />
        ) : (
          <AddressSection
            formik={{
              values,
              errors,
              touched,
              setFieldValue,
              validateForm,
              setFieldTouched,
            }}
            handleEnterKey={handleEnterKey}
            optionalFieldsToDisplay={optionalFieldsToDisplay}
            handleOnBlurSubmission={handleOnBlurSubmission}
            shouldShowText={true}
          />
        )
      default:
        return <p>{chosenPaymentMethod && `Tela de ${chosenPaymentMethod}`}</p>
    }
  }, [
    chosenPaymentMethod,
    temporaryCreditCardData,
    setTemporaryCreditCardData,
    setIsCreditCardValid,
    values,
    errors,
    touched,
    setFieldValue,
    validateForm,
    setFieldTouched,
    handleEnterKey,
    handleOnBlurSubmission,
    optionalFieldsToDisplay,
    shouldShowCourseFinancingForm,
    firstName,
    imTheCardHolder,
    setImTheCardHolder,
  ])

  const renderBasicInfoSection = useMemo(() => {
    return (
      <>
        <Input
          label="CPF"
          placeholder="816.799.670-02"
          mask={masks.cpf}
          width="368px"
          value={values?.CPF || ''}
          disabled={true}
          name="cpf"
        />
        <Input
          label="Nome completo"
          placeholder="Cecília Meireles"
          name="fullName"
          width="368px"
          value={values.fullName}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue('fullName', e.target.value)}
          onBlur={() => {
            setFieldTouched('fullName')
            validateForm()
            handleOnBlurSubmission('fullName')
          }}
          isValid={!errors.fullName && touched.fullName}
          hasError={errors.fullName && touched.fullName}
          errorMessage={errors.fullName}
          onKeyDown={handleEnterKey}
          inputRef={fullNameRef}
        />

        {optionalFieldsToDisplay.phone && (
          <Input
            inputMode="numeric"
            pattern="[0-9]*"
            type="text"
            label="Celular"
            placeholder="(11) 95771-2412"
            width="184px"
            name="phone"
            value={values.phone}
            mask={masks.phone}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue('phone', e.target.value)}
            onBlur={() => {
              setFieldTouched('phone')
              validateForm()
              handleOnBlurSubmission('phone')
            }}
            isValid={!errors.phone && touched.phone}
            hasError={errors.phone && touched.phone}
            errorMessage={errors.phone}
            onKeyDown={handleEnterKey}
          />
        )}

        <InputFieldEmail
          label="E-mail"
          placeholder="cecilia@email.com.br"
          emailValue={values.email}
          fieldName="email"
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          hasError={errors.email}
          hasTouched={touched.email}
          validateForm={validateForm}
          handleEnterKey={handleEnterKey}
          mobileWidth="100%"
        />

        <InputFieldEmail
          label="Confirme seu e-mail"
          placeholder="cecilia@email.com.br"
          emailValue={values.confirmEmail}
          fieldName="confirmEmail"
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          hasError={errors.confirmEmail}
          hasTouched={touched.confirmEmail}
          validateForm={() => {
            validateForm()
            handleOnBlurSubmission('confirmEmail')
          }}
          handleEnterKey={handleEnterKey}
          mobileWidth="100%"
        />
      </>
    )
  }, [
    fullNameRef,
    values?.CPF,
    values.fullName,
    values.email,
    values.confirmEmail,
    values?.phone,
    errors.fullName,
    errors?.phone,
    errors.email,
    errors.confirmEmail,
    touched?.phone,
    touched.fullName,
    touched.email,
    touched.confirmEmail,
    optionalFieldsToDisplay,
    setFieldValue,
    setFieldTouched,
    validateForm,
    handleEnterKey,
    handleOnBlurSubmission,
  ])

  if (isLoadingData && process.env.NODE_ENV !== 'test') {
    return (
      <div className="m-6 space-y-4 max-w-[368px]">
        <LoadingInput />
        <LoadingInput />
        <LoadingInput />
        <LoadingInput />
        <LoadingInput />
        <LoadingInput />
      </div>
    )
  }

  return (
    <div className="p-6 space-y-4">
      {shouldShowCourseFinancingForm ? (
        <Disclosure>
          {({ open }) => (
            <div className="flex flex-col w-full border-2 border-solid border-brand-primaryLight rounded-lg sm:w-[464px] sm:p-6 sm:py-4 p-4">
              <Disclosure.Button className="py-2" ref={disclosureButtonRef}>
                <span className="flex justify-between">
                  <Body2>Informações básicas</Body2>
                  <ChevronRightIcon className={open ? 'transform rotate-180' : ''} />
                </span>
              </Disclosure.Button>
              <Disclosure.Panel>
                <div className="space-y-4">
                  {renderBasicInfoSection}
                  <AddressSection
                    formik={{
                      values,
                      errors,
                      touched,
                      setFieldValue,
                      validateForm,
                      setFieldTouched,
                    }}
                    handleEnterKey={handleEnterKey}
                    optionalFieldsToDisplay={optionalFieldsToDisplay}
                    handleOnBlurSubmission={handleOnBlurSubmission}
                    shouldShowText={false}
                  />
                </div>
              </Disclosure.Panel>
            </div>
          )}
        </Disclosure>
      ) : (
        renderBasicInfoSection
      )}

      <Body1 className="pt-4" id="payment-methods-text">
        Como você prefere pagar?
      </Body1>
      <PaymentMethodCards hasInstability={false} />
      {shouldShowReason && (
        <InformationTextWithTooltip text="Por que o financiamento não está disponível?" tooltipText={reason} />
      )}
      {renderChosenPaymentMethodForm}

      {/* {shouldShowCourseFinancingForm && <CourseFinancingForm firstName={firstName} hideSubmit />} */}

      <Button
        text={submitButtonText}
        icon={true}
        mobileWidth="100%"
        disabled={isButtonDisabled}
        onClick={handleSubmit}
        isLoading={isSendingData}
      />
    </div>
  )
}
