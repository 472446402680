import { useFormik } from 'formik'
import moment from 'moment'
import { KeyboardEvent, useCallback, useContext, useEffect, useState } from 'react'
import { IBasicInfoResponse, IOptionalFieldsToDisplay, IPopulateInputsBasicInfo } from 'types'
import * as Yup from 'yup'
import { CheckoutContext } from '~/contexts/CheckoutContext'
import { errorMessages, requiredErrorMessages } from '~/enums/defaultMessages'
import { createBasicInfo, readBasicInfo } from '~/services/api'
import { removeMask } from '~/utils/removeMask'
import { getLastName, isEmailValid, isPhoneValid, validateFullName, validateBirthDate } from '~/utils/index'
import { gtagGenerateLead } from '~/utils/gtag/gtagGenerateLead'
import { getCRID } from '~/utils/getCRID'

export const useContactsTab = () => {
  const { basicInfoData, isChecked, setIsChecked, setIsLoadingData, isLoadingData, isSendingData } = useContext(CheckoutContext)
  const [isCheckboxDisabled, setIsCheckboxDisabled] = useState(false)
  const [optionalFieldsToDisplay, setOptionalFieldsToDisplay] = useState<IOptionalFieldsToDisplay>({
    birthDate: false,
    RG: false,
  })

  const proceed = useCallback(
    async (valuesAddressData) => {
      const crid = getCRID()?.toString()

      crid &&
        window?.smartlook?.('identify', crid, {
          name: valuesAddressData.fullName,
          email: valuesAddressData.email,
          CPF: valuesAddressData.CPF,
          crid,
        })

      const removeMaskPhone = removeMask(valuesAddressData.phone) as string

      const data = {
        fullName: valuesAddressData.completeName.trim(),
        phone: removeMaskPhone,
        email: valuesAddressData.email.trim(),
        RG: valuesAddressData.rg.trim(),
        birthDate: valuesAddressData.birthDate.trim(),
        fullSocialName: isChecked ? valuesAddressData.fullSocialName.trim() : '',
      }

      await createBasicInfo(data)
    },
    [isChecked],
  )

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    dirty,
    setFieldValue,
    validateForm,
    setFieldTouched,
    isSubmitting,
  } = useFormik({
    validateOnBlur: true,
    validateOnChange: true,
    initialValues: {
      CPF: '',
      completeName: '',
      fullSocialName: '',
      email: '',
      confirmEmail: '',
      phone: basicInfoData?.phone || '',
      birthDate: '',
      rg: '',
    },

    validationSchema: Yup.object({
      completeName: Yup.string().trim().required(requiredErrorMessages.fullName),
      fullSocialName: isChecked ? Yup.string().trim().required(errorMessages.requiredField) : Yup.string().nullable(),
      phone: Yup.string().required(requiredErrorMessages.phone),
      birthDate: optionalFieldsToDisplay.birthDate ? Yup.string().required(errorMessages.requiredField) : Yup.string(),
      email: Yup.string().trim().email(requiredErrorMessages.emailInvalid).required(requiredErrorMessages.emailInvalid),
      confirmEmail: Yup.string()
        .trim()
        .email(requiredErrorMessages.emailInvalid)
        .oneOf([Yup.ref('email'), null], requiredErrorMessages.confirmEmail)
        .required(requiredErrorMessages.confirmEmail),
      rg: optionalFieldsToDisplay.RG ? Yup.string().required(requiredErrorMessages.rg).max(16) : Yup.string(),
    }),

    onSubmit: proceed,

    validate: (fields: IPopulateInputsBasicInfo) => {
      const fieldsArray = Object.keys(fields)
      return fieldsArray.reduce((prev, cur) => {
        if (cur === 'completeName' && fields[cur] && !validateFullName(fields[cur])) {
          const lastName = getLastName(fields[cur])[0]
          if (!fields[cur].includes(' ') || (lastName && lastName.length < 2) || !lastName) {
            return {
              ...prev,
              [cur]: errorMessages.fullName,
            }
          }

          return {
            ...prev,
            [cur]: errorMessages.fullName,
          }
        }

        if (cur === 'fullSocialName' && fields[cur] && !validateFullName(fields[cur])) {
          const lastName = getLastName(fields[cur])[0]
          if (!fields[cur].includes(' ') || (lastName && lastName.length < 2) || !lastName) {
            return {
              ...prev,
              [cur]: 'Digite nome e sobrenome',
            }
          }

          return {
            ...prev,
            [cur]: errorMessages.fullName,
          }
        }

        if (cur === 'birthDate' && fields[cur] && !validateBirthDate(fields[cur])) {
          const wrongFormat = fields[cur].length < 10

          if (moment().diff(moment(fields[cur], 'DD/MM/YYYY'), 'years') <= 16 && !wrongFormat) {
            return {
              ...prev,
              [cur]: 'Você deve ter mais de 16 anos de idade',
            }
          }

          return {
            ...prev,
            [cur]: wrongFormat ? 'Digite a data no formato dd/mm/aaaa' : errorMessages.birthDate,
          }
        }
        if (cur === 'phone' && fields[cur] && !isPhoneValid(fields[cur])) {
          if (fields[cur].length === 1) {
            return {
              ...prev,
              [cur]: requiredErrorMessages.phone,
            }
          }
          return {
            ...prev,
            [cur]: errorMessages.phone,
          }
        }

        if (cur === 'email' && fields[cur] && !isEmailValid(fields[cur])) {
          return {
            ...prev,
            [cur]: errorMessages.emailInvalid,
          }
        }

        if (cur === 'confirmEmail' && fields[cur] && !isEmailValid(fields[cur])) {
          return {
            ...prev,
            [cur]: errorMessages.confirmEmail,
          }
        }

        return { ...prev }
      }, {})
    },
  })

  useEffect(() => {
    validateForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChecked])

  const populateInputs = useCallback(
    ({ fullName, email, phone, fullSocialName, CPF }: IBasicInfoResponse) => {
      setIsLoadingData(false)

      fullSocialName && setIsChecked(true)
      fullSocialName && setIsCheckboxDisabled(true)

      setFieldValue('CPF', CPF)
      setFieldValue('completeName', fullName || '')
      setFieldValue('email', email || '')
      setFieldValue('confirmEmail', email || '')
      setFieldValue('phone', phone || '')
      setFieldValue('fullSocialName', fullSocialName || '')

      fullName && setFieldTouched('completeName')
      email && setFieldTouched('email')
      email && setFieldTouched('confirmEmail')
      phone && setFieldTouched('phone')
      fullSocialName && setFieldTouched('fullSocialName')

      validateForm()
    },
    [setFieldValue, setFieldTouched, setIsLoadingData, validateForm, setIsChecked],
  )

  const handleEnterKey = (event: KeyboardEvent<HTMLFormElement>) => {
    if (event.key === 'Enter') {
      !isSubmitting && handleSubmit(event)
    }
  }

  useEffect(() => {
    async function getInfosBasicInfo() {
      const result = await readBasicInfo()

      if (typeof result != 'string') {
        populateInputs(result?.response)
        setOptionalFieldsToDisplay(result?.response.optionalFieldsToDisplay)

        gtagGenerateLead()
      }
    }

    getInfosBasicInfo().finally(() => setIsLoadingData(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    setFieldValue,
    handleBlur,
    isValid,
    dirty,
    populateInputs,
    handleEnterKey,
    validateForm,
    setFieldTouched,
    isLoadingData,
    optionalFieldsToDisplay,
    isSendingData,
    isChecked,
    setIsChecked,
    isCheckboxDisabled,
  }
}
