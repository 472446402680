import { ButtonV3 as Button, CheckBox, InputFieldV3 as Input, theme } from '@provi/provi-components'
import { ChangeEvent, useMemo } from 'react'
import { InputFieldEmail } from '~/components/atoms/InputFieldEmail'
import { LoadingInput } from '~/components/molecules'
import { masks } from '~/enums/masks'
import { useContactsTab } from './hooks'
import * as S from './style'

export const BasicInfoScreen = () => {
  const {
    values,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    isValid,
    dirty,
    handleEnterKey,
    validateForm,
    setFieldTouched,
    isLoadingData,
    optionalFieldsToDisplay,
    isSendingData,
    isChecked,
    setIsChecked,
    isCheckboxDisabled,
  } = useContactsTab()

  const renderFormBasicInfoLoading = useMemo(() => {
    return (
      <S.WrapperForm>
        <S.TitleForm>Identificação</S.TitleForm>

        <S.WrapperInputLoading>
          <LoadingInput />
          <LoadingInput />
          <S.LoadingInputCheckbox />
          <LoadingInput />
          <LoadingInput />
          <LoadingInput />
          <S.BasicInfoLoadingButton />
        </S.WrapperInputLoading>
      </S.WrapperForm>
    )
  }, [])

  if (isLoadingData && process.env.NODE_ENV !== 'test') {
    return renderFormBasicInfoLoading
  }

  return (
    <S.WrapperForm>
      <S.TitleForm>Identificação</S.TitleForm>

      <S.WrapperInput>
        <Input
          label="CPF"
          placeholder="816.799.670-02"
          mask={masks.cpf}
          width="368px"
          value={values?.CPF || ''}
          disabled={true}
          name="cpf"
        />
        <Input
          label="Nome completo"
          placeholder="Cecília Meireles"
          name="completeName"
          width="368px"
          value={values.completeName}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue('completeName', e.target.value)}
          onBlur={() => {
            setFieldTouched('completeName')
            validateForm()
          }}
          isValid={!errors.completeName && touched.completeName}
          hasError={errors.completeName && touched.completeName}
          errorMessage={errors.completeName}
          onKeyDown={handleEnterKey}
        />
        <CheckBox
          text="Quero informar um nome social"
          name="checkbox"
          value="checkbox"
          disabled={isCheckboxDisabled}
          readOnly={isCheckboxDisabled}
          checked={isChecked}
          selectedColor={isCheckboxDisabled ? theme.colors.greyLight : theme.colors.bluePrimary}
          onChange={() => {
            setIsChecked((prev) => !prev)

            // this conditional is being done before react rerenders, so `isChecked` here the opposite of what it should've been
            if (isChecked) {
              validateForm()
              setFieldValue('fullSocialName', '')
            } else {
              setFieldTouched('fullSocialName', false)
              validateForm()
            }
          }}
        />
        {isChecked && (
          <Input
            label="Nome Social"
            placeholder="Manu Meireles"
            width="364px"
            value={values.fullSocialName}
            name="fullSocialName"
            onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue('fullSocialName', e.target.value)}
            onBlur={() => {
              setFieldTouched('fullSocialName')
              validateForm()
            }}
            isValid={!errors.fullSocialName && touched.fullSocialName}
            hasError={errors.fullSocialName && touched.fullSocialName}
            errorMessage={errors.fullSocialName}
            onKeyDown={handleEnterKey}
          />
        )}
        {optionalFieldsToDisplay.birthDate && (
          <Input
            inputMode="numeric"
            pattern="[0-9]*"
            type="text"
            label="Data de nascimento"
            placeholder="12/12/2000"
            width="364px"
            name="birthDate"
            mask={masks.birthDate}
            value={values.birthDate}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue('birthDate', e.target.value)}
            onBlur={() => {
              setFieldTouched('birthDate')
              validateForm()
            }}
            isValid={!errors.birthDate && touched.birthDate}
            hasError={errors.birthDate && touched.birthDate}
            errorMessage={errors.birthDate}
            onKeyDown={handleEnterKey}
            required
          />
        )}
        {optionalFieldsToDisplay.RG && (
          <Input
            label="RG"
            mask={masks.rg}
            placeholder="12345678-9"
            name="rg"
            width="364px"
            value={values.rg}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue('rg', e.target.value)}
            onBlur={() => {
              setFieldTouched('rg')
              validateForm()
            }}
            isValid={!errors.rg && touched.rg}
            hasError={errors.rg && touched.rg}
            errorMessage="Informe um RG válido"
            onKeyDown={handleEnterKey}
          />
        )}
        <Input
          inputMode="numeric"
          pattern="[0-9]*"
          type="text"
          label="Celular"
          placeholder="(11) 95771-2412"
          width="184px"
          name="phone"
          value={values.phone}
          mask={masks.phone}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue('phone', e.target.value)}
          onBlur={() => {
            setFieldTouched('phone')
            validateForm()
          }}
          isValid={!errors.phone && touched.phone}
          hasError={errors.phone && touched.phone}
          errorMessage={errors.phone}
          onKeyDown={handleEnterKey}
        />

        <InputFieldEmail
          label="E-mail"
          placeholder="cecilia@email.com.br"
          emailValue={values.email}
          fieldName="email"
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          hasError={errors.email}
          hasTouched={touched.email}
          validateForm={validateForm}
          handleEnterKey={handleEnterKey}
          mobileWidth="100%"
        />

        <InputFieldEmail
          label="Confirme seu e-mail"
          placeholder="cecilia@email.com.br"
          emailValue={values.confirmEmail}
          fieldName="confirmEmail"
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          hasError={errors.confirmEmail}
          hasTouched={touched.confirmEmail}
          validateForm={validateForm}
          handleEnterKey={handleEnterKey}
          mobileWidth="100%"
        />
        <S.ButtonWrapper>
          <Button
            text="Continuar"
            type="submit"
            icon
            onClick={handleSubmit}
            disabled={!(isValid && dirty)}
            isLoading={isSendingData}
          />
        </S.ButtonWrapper>
      </S.WrapperInput>
    </S.WrapperForm>
  )
}
